import React from 'react';
import ContentPage from '../../components/ContentPage';
import PageImage from '../../components/PageImage';

import image from '../../images/undraw_app_data.svg';

function ServicesMobileappsPage() {

    return <ContentPage>
        <h1>Mobile Apps</h1>
        <p>Sometimes, a web browser is not enough. If you want to focus solely on mobile devices then we can build 
            apps that will run natively on Apple and Android devices rather than in a browser. This means that each 
            user gets the full experience and features of their device, whether it is a tablet, smartphone, or other 
            format.</p>
        <PageImage image={image} alt='Mobile app development' />
        <p>Running a native app has two advantages over using a web application on the mobile device.</p>
        <ul>
            <li>The app will be optimised for each device. It will look and feel like any other Apple or Android app. 
                It can work with the device’s hardware, including the camera, GPS, and environmental sensors where 
                they are available. And it can be tuned to balance responsiveness and performance against battery 
                consumption and other resource use.</li>
            <li>An app can work when there is no internet connection. If your application will be used in remote 
                locations, or any other site where the internet cannot be reached, then a native app on a mobile device 
                is the only way to go. Our extensive experience of offline and replicating systems lets us build mobile 
                applications that can work in environments where other tools will fail.</li>
        </ul>
    </ContentPage>

}

export default ServicesMobileappsPage;